export const API_URL = 'https://stage-cgisocial.azurewebsites.net/api';
export const FILE_UPLOAD_URL = 'https://fuss-media-dev.azurewebsites.net/api';
// export const API_URL = 'http://localhost:8080/api';
// export const FILE_UPLOAD_URL = 'http://localhost:8080/api';

export const FILE_DOWNLOAD_BASE_URL = 'https://safusssample.blob.core.windows.net/file-upload';
export const FILE_DOWNLOAD_KEY = 'sp=racwdl&st=2024-10-07T16:30:58Z&se=2025-01-02T01:30:58Z&spr=https&sv=2022-11-02&sr=c&sig=DkwRuAH2wzzeV%2Fnx%2FuejXi5yoOPQE5LWjGv%2B3wPC5%2Fo%3D';

// old 10-4  
// 'sp=rawdl&st=2024-07-29T13:18:20Z&se=2024-10-11T21:18:20Z&spr=https&sv=2022-11-02&sr=c&sig=DI%2FsSJaJy2ZN2kesy2Y6uCNRxIkHWfIN2MF0S2qUONI%3D'
// JAn 2025 
// sp=racwdl&st=2024-10-07T16:30:58Z&se=2025-01-02T01:30:58Z&spr=https&sv=2022-11-02&sr=c&sig=DkwRuAH2wzzeV%2Fnx%2FuejXi5yoOPQE5LWjGv%2B3wPC5%2Fo%3D