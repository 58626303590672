// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  msalConfig: {
    auth: {
      // 'Application (client) ID' of app registration in the Microsoft Entra admin center - this value is a GUID
      clientId: '5bba2a0b-7956-4d75-8cb0-9f0c526bcf7c',
      // Full directory URL, in the form of https://login.microsoftonline.com/<tenant>
      authority: 'https://login.microsoftonline.com/38c3ad86-7f66-495d-8305-3df8e9ca9985',
      // Must be the same redirectUri as what was provided in your app registration.
      //redirectUri: 'http://localhost:4200'
      redirectUri: 'https://stage.cgisocial.com'
    }
  },
  apiConfig: {
    // Used to sign into MS Graph
    scopes: ['User.Read'],
    uri: 'https://graph.microsoft.com/v1.0/me'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
